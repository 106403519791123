export const LOADER_STATE_CHANGE_EVT = 'emma-loading-widget-update';
export const CONTAINER_CLASSNAME = 'emma-spinner-overlay-container';
export const POST_MESSAGE_TYPE = 'emma-loading-widget';

export const isDev = () => /localhost/g.test(window.origin);

export const getTargetOrigin = () => (isDev() ? '*' : window.origin);

export const isEmmaApp = () =>
  /EMMA( [A-Za-z]+)?\/([0-9]+\.){3}[0-9]{8,10}/g.test(navigator.userAgent);

export const REGEX_EMMA_ORIGIN = /[-\w]{0,}emma.axa.com.hk/g;
/**
 * Please use port 3100 for chatbot-frontend local development
 */
export const REGEX_CHATBOT_ORIGIN =
  /emma-chatbot[-\w]{0,}.axa.com.hk|localhost:3100/g;
/**
 * Please use port 3100 for chatbot-frontend local development
 */
export const isChatbot = () => REGEX_CHATBOT_ORIGIN.test(window.origin);
/**
 * Please use port 3100 for chatbot-frontend local development
 */

// [HKEMMA-91838] [PenTest] D2 Resource Injection - document.referrer is already verified through regex
export const isFromChatbot = () => REGEX_CHATBOT_ORIGIN.test(document.referrer);

export const BUFFER_TIME_INTERVAL = 50;
export const MIN_ANIM_TIME_INTERVAL = 100;
export const MAX_ANIM_TIME_INTERVAL = 10000;

export const RHS_CONTAINER_ID = 'rhs-loader-container';
export const JS_DOWNLOADING_LOADER_ID = 'js-downloading-loader';
