import {
  isChatbot,
  isFromChatbot,
  LOADER_STATE_CHANGE_EVT,
  MAX_ANIM_TIME_INTERVAL,
  POST_MESSAGE_TYPE,
  getTargetOrigin,
} from './constants';
import { getState } from './state';
import { DispatchPostDataPayload, LoaderState, PostData } from './types';

const dispatchLoadingEventToMobile = (state: LoaderState) => {
  // @ts-ignore
  window?.ReactNativeWebView?.postMessage(
    JSON.stringify({
      action: LOADER_STATE_CHANGE_EVT,
      detail: state,
    }),
  );
};

/**
 * @category Control Loading State
 * @param message
 * @example
  dispatchLoadingMessage({
    loaderId: 'any-uuid',
    target: 'overlay',
    isLoading: true,
    content: 'spinner'
  })
 */
export const dispatchLoadingMessage = (message: DispatchPostDataPayload) => {
  const payload: PostData = {
    ...message,
    type: POST_MESSAGE_TYPE,
    maxAnimEndTime:
      Date.now() + (message.maxAnimEndTime || MAX_ANIM_TIME_INTERVAL),
  };

  if (isChatbot()) {
    window.postMessage(payload, getTargetOrigin());
    return;
  }

  if (isFromChatbot()) {
    window.parent.postMessage(payload, document.referrer);
    return;
  }

  window.top.postMessage(payload, getTargetOrigin());
};

export const dispatchLoadingUpdateEvent = () => {
  const state = getState();
  if (isChatbot()) {
    // Enable chatbot web to notify itself, without affecting emma-web
    window.dispatchEvent(
      new CustomEvent<LoaderState>(LOADER_STATE_CHANGE_EVT, {
        detail: state,
      }),
    );
    // Prevent feature frame in chatbot trigger mobile native laoding spinner
    if (state.currentTarget === document.body) {
      dispatchLoadingEventToMobile(state);
    }
    return;
  }

  if (isFromChatbot()) {
    // As chatbot's domain is different from Emma, dispatchEvent() will be blocked. Use postMessage() instead.
    window.parent.postMessage(state, document.referrer);
    return;
  }

  window.top.dispatchEvent(
    new CustomEvent<LoaderState>(LOADER_STATE_CHANGE_EVT, {
      detail: state,
    }),
  );
  dispatchLoadingEventToMobile(state);
};
